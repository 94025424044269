<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="checkbox-container">
    <div class="checkbox-input">
      <input
        type="checkbox"
        :id="id"
        :class="inputStyle"
        :value="value"
        :checked="checked"
        @input="updateValue($event.target)"
      />
      <label v-if="label" :for="id" class="sm-text">
        <template v-for="(item, index) in labelArray">
          <template v-if="index % 2 === 0">{{ item }}</template>
          <a v-else :href="links[(index - 1) / 2]" target="_blank">{{
            item
          }}</a>
        </template>
      </label>
    </div>
    <span
      class="error-message error-message--checkbox"
      style="display: block"
      >{{ errorMsg }}</span
    >
  </div>
</template>

<script>
import { translateString } from "@/functions/functions.js";

export default {
  name: "InputCheckbox",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    inputStyle: {
      type: String,
      default: "simple",
    },
    type: {
      type: String,
      default: "",
    },
    value: {
      type: [String || Boolean],
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      labelArray: [],
    };
  },
  created() {
    if (this.links.length > 0) {
      this.labelArray = this.label.split("%%");
    } else {
      this.labelArray = [this.label];
    }
  },
  methods: {
    translateString,
    updateValue: function (value) {
      this.$emit("updateInput", value);
    },
  },
};
</script>

<style lang="scss">
@import "/Inputs";
</style>
